.headroom {
  will-change: transform;
  // background-color: theme-color("primary") !important;
  @include transition($transition-base);

}

.headroom--pinned {
  @extend .position-fixed;
  transform: translateY(0%);
}

.headroom--unpinned {
  @extend .position-fixed;
  transform: translateY(-100%);
}

.headroom--not-top {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: theme-color("default") !important;
  box-shadow: 0pc 1px 10px rgba(0, 0, 0, 0.2);
}